import React                from "react"
import { navigate }         from 'gatsby'
import useInterval          from '@use-it/interval'
import {
    Container
}                           from 'react-bootstrap'
import {
    FaCreditCard,
}                           from 'react-icons/fa'
import Icon                 from 'entrada-ui/Icon'
import CartHandler          from 'components/CartHandler'
import styles               from './styles.module.scss'

const PaymentTemplate = (props) => {
    const context = React.useContext(CartHandler.Context)
    const { checkPaymentResult, paymentStatus } = context

    useInterval(() => {
        checkPaymentResult()
    }, 1000)

    return (
        <Container className={styles.payment}>
            <Icon className={styles.ccCard} fontSize="large">
                <FaCreditCard />
            </Icon>
            <span>Processing payment...</span>
        </Container>
    )
}

export default PaymentTemplate
